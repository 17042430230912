var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationForm',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('Modal',{attrs:{"width":"850px"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"cassie-vertical-sm"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('v-spacer'),_c('IconButton',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" mdi-close ")])]},proxy:true},{key:"modal-content",fn:function(){return [(_vm.isEdit && _vm.theme.cassieDefault)?_c('Message',{attrs:{"type":"warning"}},[_vm._v(" You can not change the name or upload files for default themes ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{staticClass:"cassie-input-width-xl",attrs:{"disabled":_vm.readOnly,"label":"Theme Name *","rules":{required: true}},model:{value:(_vm.theme.themeName),callback:function ($$v) {_vm.$set(_vm.theme, "themeName", $$v)},expression:"theme.themeName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"disabled":_vm.readOnly,"label":"Logo URL"},model:{value:(_vm.theme.logoUrl),callback:function ($$v) {_vm.$set(_vm.theme, "logoUrl", $$v)},expression:"theme.logoUrl"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"disabled":_vm.readOnly,"label":"Logo Link"},model:{value:(_vm.theme.logoLink),callback:function ($$v) {_vm.$set(_vm.theme, "logoLink", $$v)},expression:"theme.logoLink"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Javascript"),_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"light":""}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" Upload your script.js file ")])])],1),_c('validation-provider',{attrs:{"rules":"isValidScript:js"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"disabled":_vm.readOnly,"counter":"","show-size":"","label":"Javascript","accept":"text/javascript","truncate-length":"50","error-messages":errors.length ? [errors[0]] : []},model:{value:(_vm.theme.script),callback:function ($$v) {_vm.$set(_vm.theme, "script", $$v)},expression:"theme.script"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Style"),_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"light":""}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" Upload your style.css file ")])])],1),_c('validation-provider',{attrs:{"rules":"isValidCss:css"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"disabled":_vm.readOnly,"counter":"","show-size":"","label":"CSS (style.css)","accept":".css","truncate-length":"50","error-messages":errors.length ? [errors[0]] : []},model:{value:(_vm.theme.style),callback:function ($$v) {_vm.$set(_vm.theme, "style", $$v)},expression:"theme.style"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Resources"),_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"light":""}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" Resource files must be less than 5MB in size. Allowed file formats: jpg, jpeg, png, gif, svg, tiff and ttf ")])])],1),_c('validation-provider',{attrs:{"rules":"isValidResource:jpg,jpeg,png,gif,svg,tiff,ttf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"disabled":_vm.readOnly,"multiple":"","counter":"","show-size":"","label":"Resources","accept":".png, .jpg, .jpeg, .gif, .svg, .tiff, .ttf","error-messages":errors.length ? [errors[0]] : [],"truncate-length":"50"},model:{value:(_vm.selectedResources),callback:function ($$v) {_vm.selectedResources=$$v},expression:"selectedResources"}})]}}],null,true)}),_c('v-col')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(" Assets ")]),_c('p',[_vm._v(" Note files will be uploaded to your public portal folders in the following structure ")]),(!_vm.isEdit || _vm.readOnly)?[_c('p',[_vm._v(" Javascript: "),_c('br'),_vm._v(" /Themes/[ClientID]/[ThemeID]/js/script.js "),(_vm.isEdit && _vm.theme.cassieDefault)?_c('a',{on:{"click":function($event){return _vm.downloadExampleFile('js')}}},[_vm._v(" Default Example ")]):_vm._e()]),_c('p',[_vm._v(" CSS: "),_c('br'),_vm._v(" /Themes/[ClientID]/[ThemeID]/css/style.css "),(_vm.isEdit && _vm.theme.cassieDefault)?_c('a',{on:{"click":function($event){return _vm.downloadExampleFile('css')}}},[_vm._v(" Default Example ")]):_vm._e()]),_c('p',[_vm._v(" Resources: "),_c('br'),_vm._v(" /Themes/[ClientID]/[ThemeID]/resources/[file.ext] ")])]:_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredResources},on:{"click:row":_vm.downloadAsset},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{attrs:{"tooltip-text":"Download File"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadAsset(item)}}},[_vm._v(" mdi-file-download ")]),_c('IconButton',{attrs:{"tooltip-text":"Delete File"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteAsset(item)}}},[_vm._v(" mdi-trash-can ")])]}}],null,true)})],2)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('v-spacer'),_c('SecondaryActionButton',{on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Cancel ")]),(!_vm.isEdit || !_vm.readOnly)?_c('PrimaryActionButton',{attrs:{"disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(!_vm.isEdit ? 'Create' : 'Save')+" ")]):_vm._e()]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }