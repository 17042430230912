/* eslint-disable */
export const scriptExample = `function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function ChangePanel(panel) {
    var LinkSelector = "#" + panel + "Link";
    var PanelSelector = "#" + panel + "Panel";

    $(".MainMenuListItem").removeClass("active");
    $(LinkSelector).parent().addClass("active");
    $(".panel").hide();
    $(PanelSelector).fadeIn();
}

function ChangePrefPanel(grouping, subgrouping) {
    var PanelSelector = "#pp" + grouping + "_" + subgrouping;
    $(".preferences-panel").hide();
    $(".dropdown-menu").hide();
    $(".dropdown-menu-item").removeClass("active");
    $(".PreferencesMenuListItem").removeClass("active");

    //Keep the dropdown menu open
    if(grouping == "0"){
        $("#g0_sg0").addClass("active");
    }
    else {
        var DDSelector = "#dd_" + grouping;
        $(DDSelector).show();
        
        var SubMenuSelector = "#g" + grouping + "_sg" + subgrouping;
        $(SubMenuSelector).addClass("active");
    }

    $(PanelSelector).fadeIn();

    var accordionSelector = "#accordion_" + grouping;

    if ($(accordionSelector).parent().hasClass("opt-in")) {
        $(accordionSelector).show(); 
    }

    var cardSelector = "#card_" + grouping;
    var anchorTag = $(cardSelector);
    $('html,body').animate({ scrollTop: anchorTag.offset().top }, 400);
}

$(document).ready(function(){
    var panel = getParameterByName("panel");
    if(panel!="" && panel != null){
        ChangePanel(panel);
    }
    else {
        ChangePanel("Preferences");
    }

    var ChannelGrouping = getParameterByName("g");
    if(ChannelGrouping!="" && ChannelGrouping != null){
        if(ChannelGrouping == "0"){
            ChangePrefPanel(0, 0);
        }
        else {
            var SubGrouping = getParameterByName("sg");
            ChangePrefPanel(ChannelGrouping, SubGrouping);
        }
    }
    else {
        ChangePrefPanel(0, 0);
    }
});

$("#MainMenuButton").on("click", function(){
    $("#MainMenu").toggleClass("show");
});

$(".history-statement-button").on("click", function(){
    var targetSelector = "#" + $(this).attr("target");
    $(targetSelector).slideToggle();
});

$(".dropdown-link").on("click", function(){
    event.preventDefault();
});

$(".PreferencesMenuListItem:not(.dropdown-menu-item)").not(".dropdown-menu-item").on("click", function(){
    var targetSelector = "#" + $(this).attr("target");
    $(targetSelector).slideToggle();
});

$("#PreferencesMenuMobile").on("click", function(){
    $("#PreferencesMenu").toggleClass("show");
}); 

$(".MainMenuListItem").on("click", function () {
    event.preventDefault();
    var panel = $(this).children().attr("id");
    if (panel == "PreferencesLink") {
        ChangePanel("Preferences");
    } else if (panel == "DetailsLink") {
        ChangePanel("Details");
    } else if (panel == "HistoryLink") {
        ChangePanel("History");
    } else if (panel == "PromiseLink") {
        ChangePanel("Promise");
    } else if (panel == "LogoutLink") {
        window.location = "../Home/?status=logout";
    }
});

$(".dropdown-menu-item").on("click", function () {
    event.preventDefault();
    var id = this.id;
    var arr = id.split("_");
    var g = arr[0].substring(1);
    var sg = arr[1].substring(2);
    ChangePrefPanel(g, sg)
});

$(".PreferencesMenuListItem").on("click", function () {
    event.preventDefault();
    var id = this.id;
    var arr = id.split("_");
    var g = arr[0].substring(1);
    var sg = arr[1].substring(2);
    ChangePrefPanel(g, sg)
});

$(".MainMenuListItem").on("click", function () {
    $("#MainMenu").removeClass("show");
});


//Custom functionality for popups when clicking "Change this"

//Commenting out old "on change this" click handler.
//$(".change-this-button").on("click", function () {
//    var id = this.getAttribute("target");

//    var target = "#accordion_" + id;

//    $(target).slideToggle();
//});

var optOutButtons = document.getElementsByClassName("change-this-button opt-out");
for (i = 0; i < optOutButtons.length; i++) {
    var HTML = optOutButtons[i].parentNode.innerHTML;
    var GroupID = optOutButtons[i].getAttribute("target");

    var cardTarget = "#card_" + GroupID;
    var cardName = $(cardTarget).find(".preferences-card-title").html();

    var cardContent = "";

    //Please note - the below is reliant on the respective fields being displayed on the Details panels, as the values are being pulled from there.

    if (cardName.indexOf("Email") != -1) {
        cardContent = "<p>Please make sure that your details are correct:</p>";
        cardContent = cardContent + "<p>Email: " + $("#Email1").val() +"</p>";
        cardContent = cardContent + "<p>If the above details are wrong, please <a href='/Portal/Index?panel=Details'>click here</a> to amend them</p>";
    }
    else if (cardName.indexOf("Mail") != -1) {
        cardContent = "<p>Please make sure that your details are correct:</p>";
        cardContent = cardContent + "<p>Address:</p><p>";

        if ($("#Address1").val() != "") {
            cardContent = cardContent + $("#Address1").val();
        }
        if ($("#Address2").val() != "") {
            cardContent = cardContent + "<br>" + $("#Address2").val();
        }
        if ($("#Address3").val() != "") {
            cardContent = cardContent + "<br>" + $("#Address3").val();
        }
        if ($("#Town").val() != "") {
            cardContent = cardContent + "<br>" + $("#Town").val();
        }
        if ($("#County").val() != "") {
            cardContent = cardContent + "<br>" + $("#County").val();
        }
        if ($("#Postcode").val() != "") {
            cardContent = cardContent + "<br>" + $("#Postcode").val();
        }
        cardContent = cardContent + "</p><p>If the above details are wrong, please <a href='/Portal/Index?panel=Details'>click here</a> to amend them</p>";
    }
    else if (cardName.indexOf("Telephone") != -1) {
        cardContent = "<p>Please make sure that your details are correct:</p>";
        cardContent = cardContent + "<p>Telephone: " + $("#Telephone1").val() + "</p>";
        cardContent = cardContent + "<p>If the above details are wrong, please <a href='/Portal/Index?panel=Details'>click here</a> to amend them</p>";
    }
    else if (cardName.indexOf("SMS") != -1) {
        cardContent = "<p>Please make sure that your details are correct:</p>";
        cardContent = cardContent + "<p>Mobile: " + $("#Mobile").val() + "</p>";
        cardContent = cardContent + "<p>If the above details are wrong, please <a href='/Portal/Index?panel=Details'>click here</a> to amend them</p>";
    }
    else {
        cardContent =  "<p>Please make sure your details are correct by <a href='/Portal/Index?panel=Details'>clicking here</a></p>";
    }

    var FinalHTML = HTML;
    FinalHTML = FinalHTML + "<div class='confirm-popup' id='popup_" + GroupID + "'>";
    FinalHTML = FinalHTML + "<div class='close-popup' id='"+GroupID+"'>";
    FinalHTML = FinalHTML + "&times;";
    FinalHTML = FinalHTML + "</div>";
    FinalHTML = FinalHTML + "<div class='popup-content'>";
    FinalHTML = FinalHTML + cardContent;
    FinalHTML = FinalHTML + "<button class='button confirm-popup-button' id='" + GroupID + "'>Continue</button>";
    FinalHTML = FinalHTML + "</div>";
    FinalHTML = FinalHTML + "</div>";

    optOutButtons[i].parentNode.innerHTML = FinalHTML;
}

$(".change-this-button").not(".opt-out").on("click", function () {
    var id = this.getAttribute("target");

    var target = "#accordion_" + id;

    $(target).slideToggle();
});

$(document).on("click", ".change-this-button.opt-out", function () {
    var id = $(this).attr("target");

	if ($(this).hasClass("overwrite-opt-in"))
	{
		var target = "#accordion_" + id;

		$(target).slideToggle();
	}
	else
	{
		var cardTarget = "#card_" + id;
		var cardName = $(cardTarget).find(".preferences-card-title").html();

		var showPopup = false;

		if (cardName.indexOf("Email") != -1) {
			showPopup = true;
		}
		else if (cardName.indexOf("Mail") != -1) {
			showPopup = true;
		}
		else if (cardName.indexOf("Telephone") != -1) {
			showPopup = true;
		}
		else if (cardName.indexOf("SMS") != -1) {
			showPopup = true;
		}
		else {
			var targetForm = "#form_" + id;
			var checkboxes = ".checkbox_" + id;
			$(checkboxes).attr("checked", true);
			$(targetForm).submit();
		}

		if(showPopup) {
			var target = "#popup_" + id;
			$(target).fadeIn();
		}
	}
	
    
    
});

$(document).on("click", ".close-popup", function () {
    var id = $(this).attr("id");
    var target = "#popup_" + id;

    $(target).fadeOut();
});

$(document).on("click", ".confirm-popup-button", function () {
	
    var id = $(this).attr("id");
    //var target = "#form_" + id;

    //var checkboxes = ".checkbox_" + id;
    //$(checkboxes).attr("checked", false);
	
	var target = "#accordion_" + id;
	$(target).slideToggle();
	
	var popup = "#popup_" + id;
	$(popup).css("display","none");
	
	var targetButton = "#card_" + id;
	$(targetButton).addClass("opt-in");
	$(targetButton).removeClass("opt-out");
	$(targetButton).find(".change-this-button").addClass("overwrite-opt-in");
	$(targetButton).find(".change-this-button").addClass("opt-in");
	
    //$(target).submit();
});

//Update each card display job

//Loop through each card
$('.preferences-card').each(function(i, obj) {
    if($(this).hasClass("opt-out"))
	{
		$(this).find(".preferences-card-body").find(".preferences-card-summary-text").html("You are not set to receive communications via "+$(this).find(".preferences-card-title").html()+". Click on the \"Change this\" button to update your preferences.");
	}
	else
	{
		$(this).find(".preferences-card-body").find(".preferences-card-summary-text").html("Thank you. You are now set to receive communications via "+$(this).find(".preferences-card-title").html()+". You can click on the \"Change this\" button to update your preferences at any time.");
	}

	
}); `

export const styleExample = `@import url('https://fonts.googleapis.com/css?family=Nunito');

body {
    font-family: 'Nunito',arial,sans-serif;
    margin: 0;
    padding: 0;
    background: whitesmoke;
}

#body-container {
    max-width: 1024px;
    margin: 0 auto;
    background: white;
}

#login-body-container {
    max-width: 700px;
    margin: 0 auto;
}

#ClientLogo {
    /*display: inline-block;*/
    float: right;
}

    #ClientLogo > img {
        width: 180px;
        padding: 10px;
		padding-right:20px;
		padding-top:0px;
    }

#ClientName {
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    position: absolute;
    top: 22px;
    margin-left: 10px;
}

#MainMenu {
    list-style-type: none;
}

.MainMenuListItem {
    display: inline-block;
    border: 1px solid gainsboro;
    border-bottom: none;
    padding: 7px;
}

.MainMenuLink {
    color: black;
    text-decoration: none;
    padding: 16px;
    font-size: 15px;
}

.MainMenuListItem:hover {
    background: gainsboro;
}

.MainMenuListItem.active {
    background-color: #3D4889;
    border: 1px solid #3D4889;
    cursor: pointer;
}

    .MainMenuListItem.active > .MainMenuLink {
        color: white;
    }

.panel {
    padding: 10px;
    display: none;
}

.inner-panel {
    text-align: center;
	
}

.panel-intro {
	display: inline-block;
    text-align: left;
	max-width: 75%;

}

.details-form-group {
    padding: 5px;
}

    .details-form-group > label {
        display: inline-block;
        width: 150px;
        text-align: right;
        padding-right: 10px;
        vertical-align: top;
    }


@media only screen and (max-width: 361px) {
    .details-form-group > label {
        width: 140px;
    }
}

    
@media only screen and (max-width: 321px) {
    .details-form-group > label {
        width: 100px;
    }
}




#SaveDetailsButton {
    margin-top: 15px;
}

.button {
    background-color: #e00a0f;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 8px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Nunito',arial,sans-serif;
}

    .button:hover {
        background-color: #aa080b;
    }

.table {
    margin: 0 auto;
}

input[type="text"] {
    font-family: 'Nunito',arial,sans-serif;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 5px;
    min-width: 275px;
}

select {
    font-family: 'Nunito',arial,sans-serif;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 5px;
    min-width: 275px;
}

.login-panel {
    text-align: center;
    padding: 30px 10px;
}

#LoginLogo {
    margin: 0 auto;
}


    #LoginLogo > img {
        height: 150px;
    }


#LoginName {
    font-size: 30px;
    font-weight: bold;
}

.login-field {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    font-size: 16px;
    padding: 10px !important;
}

#login-body-container {
    position: relative;
}

#LoginName {
    margin-top: 10px;
}

.login-text {
    font-size: 12pt;
}

#start-over {
    background-color: #3D4889;
}

    #start-over:hover {
        background-color: #3D4889;
    }

#MainMenuHamburger {
    display: none;
}

#MainMenu {
    width: 100%;
    background: #dadad9;
    padding: 0;
    text-align: center;
}

@media only screen and (max-width: 800px) {
    #MainMenu {
        display: none;
        margin-top: 30px;
        background: white;
        width: 100%;
        text-align: left;
    }

    .MainMenuListItem {
        display: block;
        margin-bottom: 5px;
        border: 1px solid gainsboro;
        border-radius: 5px;
    }

    #MainMenuHamburger {
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .MainMenuLink {
        color: black;
    }

    #ClientLogo > img {
        width: 80px;
    }

    #top-bar {
        border-bottom: 1px solid gainsboro;
    }
}

@media only screen and (min-width: 800px) {
    #MainMenu {
        display: block;
    }
}

@media only screen and (max-width: 800px) {
    #ClientName {
        display: none;
    }
}

@media only screen and (max-width: 470px) {
    .details-form-group > label {
        text-align: right;
        margin-left: -25px;
        margin-bottom: 5px;
    }

    input[type="text"] {
        min-width: 200px;
    }

    .login-field {
        min-width: 100% !important;
    }
}

.table > tbody > tr > td {
    vertical-align: top;
    border: none;
    padding: 10px;
}

.table {
    width: 60%;
    border-collapse: collapse;
    margin-bottom: 20px;
}


@media only screen and (max-width: 1024px) {
    .table {
        width: 90%;
    }
}


tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

.history-col-1 {
    text-align: center;
}

.fas {
    font-size: 25px;
}

.history-channel-list > p {
    margin: 0;
}

.history-col-2 > p {
    margin-top: 2px;
}

.history-statement {
    display: none;
    padding-bottom: 10px;
}

.history-statement-button {
    margin-bottom: 10px;
}

#login-button-1 {
    margin-top: 15px;
}

#login-button-2 {
    margin-top: 15px;
}

#start-over {
    margin-top: 15px;
}

.dropdown-menu {
    display: none;
}

#PreferencesMenu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu {
    list-style-type: none;
    padding: 0;
    padding-left: 15px;
}

.PreferencesMenuLink {
    color: white;
    text-decoration: none;
}

.PreferencesMenuListItem {
    padding: 10px;
}

.withDropdown {
    cursor: pointer;
}

.dropdown-icon {
    float: right;
    color: white;
}

.dropdown-menu-item > a {
    color: white;
    text-decoration: none;
}

.dropdown-menu-item {
    padding: 5px;
}

.PreferencesMenuLink:hover {
    text-decoration: underline;
}

.dropdown-menu-item > a:hover {
    text-decoration: underline;
}

#PreferencesMenuContainer {
    width: 20%;
    background-color: #e00a0f;
    float: left;
    /*height: 100%;*/
    padding-top: 10px
}

#PreferencesPanelsContainer {
    width: 75%;
    display: inline-block;
    padding: 15px;
    /*margin-top: -30px;*/
}

.preferences-panel {
    display: none;
}

.dropdown-menu-item.active > a {
    text-decoration: underline;
}

.PreferencesMenuListItem.active > a {
    text-decoration: underline;
}

.savePreferencesButton {
    margin-top: 10px;
}

.pref-checkbox-container {
    margin-bottom: 5px;
}

#PreferencesMenuMobile {
    display: none;
    padding-left: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    width: 100%;
}

@media only screen and (max-width: 900px) {
    #PreferencesMenuContainer {
        float: none;
        width: 100%;
    }

    #PreferencesMenuMobile {
        display: inline-block;
    }

    #PreferencesMenu {
        display: none;
    }

    #PreferencesMenuContainer {
        height: unset;
    }
}

@media only screen and (max-width: 800px) {
    #PreferencesPanelsContainer {
        margin-top: -10px;
    }
}

#selected-group {
    color: white;
}

#change-grouping-mobile-logo {
    float: left;
    color: white;
    margin-top: -2px;
    margin-right: 10px;
}

#PreferencesMenu.show {
    display: block;
}

#MainMenu.show {
    display: block;
    background: white;
    width: 100%;
    margin: 0;
    padding: 5px;
    position: absolute;
    top: 60;
    left: 0;
    border-bottom: 1px solid gainsboro
}

    #MainMenu.show > .MainMenuListItem {
        width: 92%;
    }

/*.preferences-panel {
    min-height: 1000px;
}*/

.page-footer {
    width: 100%;
    background: #fff;
    padding-top: 0px;
    margin-top: 10px;
    position: relative;
    display: block;
}

body {
    margin: 0;
    color: #000;
}

.socials {
    text-align: center;
}

.container, .container-fluid, .sub-container {
    max-width: 940px;
    margin: 0 auto;
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.socials-holder {
    margin-bottom: 30px !important;
}

.socials-holder {
    overflow: hidden;
    margin: 0 0 45px;
}

.socials .live-on {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
    margin-top: 15px
}

.join-the-legion {
    background: #333;
    padding: 15px;
    text-align: center;
}

.center {
    text-align: center;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.copyright {
    background: #484747;
    color: #fff;
    text-align: center;
    padding: 30px;
    font-size: 14px;
    line-height: 24px;
}

.footerAnchor {
    color: #ffffff;
}

h2.heading span {
    position: relative;
    background: 0 0;
    padding: 0 15px;
    display: inline-block;
    vertical-align: top;
}

h2.heading {
    text-align: center;
    margin: 0 0 30px;
    display: block;
    padding: 0 15px;
    overflow: hidden;
    text-transform: uppercase;
    margin: 0 0 30px 0;
}

h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito',arial,sans-serif;
    line-height: 1.2em;
    margin: 0 0 1.2em;
}

    h2.heading span:after, h2.heading span:before {
        content: "";
        background: #6d665d;
        height: 1px;
        width: 9999px;
        position: absolute;
        right: 100%;
        top: 50%;
    }

    h2.heading span:after {
        right: auto;
        left: 100%;
    }

.socials-list li a {
    color: #fff;
    display: block;
    font-size: 20px;
    line-height: 44px;
    height: 44px;
    width: 44px;
    background: #484747;
    border-radius: 2px;
    position: relative;
}

a {
    text-decoration: none;
    color: #e00a0f;
}

.breadcrumb ul, .header-part .sub-nav ul, .socials-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}

.svg-inline--fa.fa-w-14 {
    width: .875em;
}

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
}

.fa-facebook {
    margin-top: 7px;
    font-size: 30px;
}

.fa-twitter {
    margin-top: 7px;
    font-size: 30px;
}

.fa-google-plus-g {
    margin-top: 7px;
    font-size: 30px;
}

.fa-pinterest {
    margin-top: 7px;
    font-size: 30px;
}

.fa-youtube {
    margin-top: 7px;
    font-size: 30px;
}

.fa-instagram {
    margin-top: 7px;
    font-size: 30px;
}

.fa-tumblr {
    margin-top: 7px;
    font-size: 30px;
}

.fa-linkedin-in {
    margin-top: 7px;
    font-size: 30px;
}

.socials-list li {
    display: inline-block;
    vertical-align: top;
    padding: 7px;
}

.logoicon {
    height: 150px;
    max-width: 150px;
    margin-bottom: 20px;
}

.link {
    background-color: #e00a0f;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 8px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Nunito',arial,sans-serif;
}

    .link:hover {
        background-color: #aa080b;
    }

#ClientName {
    font-size: 15px;
    font-weight: normal;
    padding: 15px;
}

#ClientBranding {
    height: 120px;
}



@media only screen and (min-width: 800px) {

    #LogoutLinkListItem {
        position: absolute;
        top: 97px;
        margin-left: -5px;
        background: #e00a0f;
        border: none;
        border-radius: 5px;
		color: white;
    }

    #PreferencesLinkListItem {
        margin-left: 145px;
    }

    #LogoutLinkListItem:hover {
        background: #aa080b;
    }
}

.change-this-button.opt-out.overwrite-opt-in{
	background-color:#3D4889;
}

.change-this-button.opt-out.overwrite-opt-in:hover{
	
	background: #3D4889;
}

.footer-logo {
    display: inline-block;
    padding: 2px 10px;
}

.change-this-button.opt-out {
    background: #e00a0f;
}

    .change-this-button.opt-out:hover {
        background: #aa080b;
    }

#PromisePanel > .inner-panel {
    text-align: left;
    padding: 0 15px;
}

.panel-title {
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    font-size: 20pt;
    color: #3D4889;
    margin-bottom: 0;
}

#promise-center {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.history-statement-button {
    background: #3D4889;
}


    .history-statement-button:hover {
        background: #3D4889;
    }

.address-lookup-button {
    background: #3D4889;
}

    .address-lookup-button:hover {
        background: #3D4889;
    }

#SaveDetailsButton {
    background: #3D4889;
}

    #SaveDetailsButton:hover {
        background: #3D4889;
    }

.savePreferencesButton {
    background: #3D4889;
}

    .savePreferencesButton:hover {
        background: #3D4889;
    }


.opt-in-icon {
    color: #3D4889;
}

.opt-out-icon {
    color: #e00a0f;
}

#PromiseContent {
    margin-top: 1rem;
}

#Preferencespanel > .inner-panel > .panel-intro {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 1rem;
}

/*#PreferencesMenuContainer {
    display: none;
}*/

.PreferencesMenuListItem {
    text-align: left;
}

.preferences-card {
    max-width: 800px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    margin: 20px auto;
    text-align: left;
}

.preferences-card-title {
    background: gainsboro;
    padding: 15px;
}

.preferences-card-body {
    padding: 15px;
}

.change-this-button {
    background: #3D4889;
}

    .change-this-button:hover {
        background: #3D4889;
    }

.change-this-button-container {
    float: right;
    margin-top: -35px;
}

.preferences-accordion {
    display: none;
    padding: 15px;
    border-top: 1px solid gainsboro;
}

.sub-card-title {
    color: #3D4889;
    font-weight: bold;
    margin-bottom: 5px;
}

.preferences-sub-card {
    /*max-width: 380px;*/
	width:100%;
    min-width: 380px;
    display: inline-block;
    padding-bottom: 15px;
    vertical-align: top;
}

.save-preferences-button-container {
    text-align: center;
    margin-bottom: 20px;
}

form {
    margin-bottom: 0;
}

.opt-out-all-container {
    border-top: 1px solid gainsboro;
    text-align: center;
    padding-top: 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
}

.preferences-card-summary-text {
    padding-top: 10px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .preferences-card-summary-text {
        padding-top: 10px;
        padding-bottom: 40px;
    }
}

.preferences-card.opt-out > .preferences-card-title {
    background: #f1e5e0;
}

.confirm-popup {
    display: none;
    background: white;
    padding: 10px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    position: absolute;
    margin-top: -160px;
    margin-left: -80px;
    z-index: 1;
}



.close-popup {
    cursor: pointer;
    float: right;
    font-size: 30px;
    margin-top: -10px;
    margin-right: -4px;
    color: grey;
}

.confirm-popup-button {
    background: #3D4889;
}

    .confirm-popup-button:hover {
        background: #3D4889;
    }

.popup-content {
    text-align: center;
    width: 300px;
}

    .popup-content > p {
        text-align: left;
    }

        .popup-content > p > a {
            color: #3D4889;
        }
@media only screen and (max-width: 1080px) {
    .confirm-popup {
        margin-left: -130px;
    }
    .popup-content {
        width: auto;
    }
}

#LogoutLink {
    color: white;
}

#card_1 .preferences-card-title {
	padding-left: 40px;
	background-image:url('/assets/britishlegion/css/email-icon.png');
	background-repeat: no-repeat;
  	background-size: 36px;

}

/*
.preferences-card.opt-out .preferences-card-summary-text {
	visibility: hidden;
	position: relative;

}
*/

.preferences-card-summary-text {
	width:80%;
	
}
/*
.preferences-card.opt-out .preferences-card-summary-text:after {
	visibility: visible;
	position: absolute;
	top: 0;
	left: 0;
	content: 'You are not set to receive communications via this channel. Click on the "Change this" button to update your preferences.';

}
*/

#card_2 .preferences-card-title {
	padding-left: 40px;
	background-image:url('/assets/images/mail-icon.png');
	background-repeat: no-repeat;
  	background-size: 36px;

}

#card_3 .preferences-card-title {
    padding-left: 40px;
    background-image: url('/assets/images/sms-icon.png');
    background-repeat: no-repeat;
    background-size: 36px;
}

#card_4 .preferences-card-title {
    padding-left: 40px;
    background-image: url('/assets/images/phone-icon.png');
    background-repeat: no-repeat;
    background-size: 36px;
}


.Email-icon-in {
    background-image: url('/assets/images/email-icon-green.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.Email-icon-out {
    background-image: url('/assets/images/email-icon-red.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.Mail-icon-in {
    background-image: url('/assets/images/mail-icon-green.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.Mail-icon-out {
    background-image: url('/assets/images/mail-icon-red.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.Telephone-icon-in {
    background-image: url('/assets/images/phone-icon-green.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.Telephone-icon-out {
    background-image: url('/assets/images/phone-icon-red.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.SMS-icon-in {
    background-image: url('/assets/images/sms-icon-green.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.SMS-icon-out {
    background-image: url('/assets/images/sms-icon-red.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.cookies-icon-out {
    background-image: url('/assets/images/cookie-icon-optout.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.cookies-icon-in {
    background-image: url('/assets/images/cookie-icon-optin.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}

.callcentre-icon {
    background-image: url('/assets/images/call-centre-icon.png');
    background-repeat: no-repeat;
    background-size: 50px;
    height: 50px;
    width: 50px;
}


`
