<template>
	<Modal width="800px">
		<template #modal-title>
			{{ theme.themeName }} Theme Use Mapping
			<v-spacer />
			<IconButton @click="$emit('close')">
				mdi-close
			</Iconbutton>
		</template>
		<template #modal-content>
			<div class="cassie-vertical-md">
				<DataTable
					:headers="tableHeaders"
					:items="theme.usedBy"
					sort-by="linkId"
					sort-desc
				/>
			</div>
		</template>
		<template #modal-footer>
			<v-spacer />
			<SecondaryActionButton @click="$emit('close')">
				Cancel
			</SecondaryActionButton>
		</template>
	</Modal>
</template>
<script>
import Modal from '../../../../../shared/components/modal.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
export default {
	components: { Modal, DataTable, SecondaryActionButton, IconButton },
	props: {
		theme: {
			type: Object,
			required: true
		}
	},
	computed: {
		tableHeaders () {
			return [
				{
					text: 'ID',
					value: 'linkId',
					width: '20%'
				},
				{
					text: 'Link Name',
					value: 'linkName',
					width: '40%'
				},
				{
					text: 'Link Type',
					value: 'linkType',
					width: '40%'
				}
			]
		}
	}
}
</script>
