<template>
	<ThemesLayout>
		<template #content>
			<div class="cassie-vertical-md">
				<FilterBar
					:hide-brand-filter="true"
					:search-query.sync="searchQuery"
					search-query-label="Theme Name"
				>
					<template #action>
						<PrimaryActionButton
							v-if="canCreateAndUpdate"
							@click="onCreateClick"
						>
							<v-icon
								left
								dark
							>
								mdi-plus
							</v-icon>
							Create
						</PrimaryActionButton>
					</template>
				</FilterBar>
				<SectionCard>
					<template #body>
						<DataTable
							:headers="tableHeaders"
							:items="filteredThemes"
							sort-by="themeId"
							:sort-desc="true"
							@click:row="onEditClick"
						>
							<template #item.cassieDefault="{ item }">
								{{ item.cassieDefault ? 'Default' : 'Custom' }}
							</template>
							<template #item.isActive="{ item }">
								{{ item.isActive ? 'Yes' : 'No' }}
							</template>
							<template #item.action="{ item }">
								<IconButton
									v-if="canCreateAndUpdate && !item.cassieDefault && !item.readOnly"
									tooltip-text="Edit Theme"
									@click="onEditClick(item)"
								>
									mdi-pencil
								</IconButton>
								<IconButton
									v-else
									tooltip-text="View Theme"
									@click="onEditClick(item)"
								>
									mdi-eye
								</IconButton>
								<IconButton
									v-if="item.usedBy.length > 0"
									tooltip-text="View Theme Mapping"
									@click.stop.prevent="themeMappingToView = item"
								>
									mdi-relation-one-to-many
								</IconButton>
								<IconButton
									v-if="!item.cassieDefault && !item.isActive && !item.readOnly && canDelete"
									tooltip-text="Delete Theme"
									@click.stop.prevent="onRemoveThemeClick(item)"
								>
									mdi-trash-can
								</IconButton>
							</template>
						</DataTable>
					</template>
				</SectionCard>
				<ConfirmDeleteModal
					v-if="themeToBeRemoved && !themeToBeRemoved.readOnly && canDelete"
					:entity-name="themeToBeRemoved.themeName"
					entity-type="Theme"
					@close="themeToBeRemoved = null"
					@delete="deleteTheme"
				/>
			</div>
			<ManageThemeModal
				v-if="showManageModal"
				:theme-to-edit="themeToEdit"
				:can-create-and-update="canCreateAndUpdate"
				@create="createTheme"
				@update="updateTheme"
				@close="closeManageModal"
			/>
			<ThemeMappingModal
				v-if="themeMappingToView"
				:theme="themeMappingToView"
				@close="closeThemeMappingModal"
			/>
		</template>
	</ThemesLayout>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import IconButton from '../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import ThemesLayout from './theme-layout.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import ManageThemeModal from './manage-theme-modal.vue'
import ThemeMappingModal from './theme-mapping-modal.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import { getThemes, getThemeUrls, deleteTheme, createTheme, updateTheme } from '../../../../../shared/utils/api/themes.js'
import { DATE_FORMAT } from '../../../../../shared/utils/date-formatting.js'
import { brandOptions, waitForLoad } from '../../../../../shared/state/brands.js'
import { CAN_CREATE_UPDATE_THEMES, CAN_DELETE_THEMES } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
export default {
	components: {
		ThemesLayout,
		FilterBar,
		ConfirmDeleteModal,
		PrimaryActionButton,
		SectionCard,
		DataTable,
		IconButton,
		ManageThemeModal,
		ThemeMappingModal
	},
	setup () {
		return { brandOptions, waitForLoad, showSnackbar }
	},
	data () {
		return {
			themes: [],
			themeToBeRemoved: null,
			searchQuery: '',
			editTheme: null,
			showModal: false,
			themeUrls: [],
			themeMappingToView: null,
			showManageModal: false,
			themeToEdit: null,
			lookupData: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		filteredThemes () {
			return this.themes.filter(theme => {
				return theme.themeName.toLowerCase().includes(this.searchQuery.toLowerCase())
			}).map(t => {
				return {
					...t,
					readOnly: this.brandOptions.find(b => b.value === t.brandId) === undefined
				}
			})
		},
		tableHeaders () {
			return [
				{ value: 'themeId', text: 'ID', width: '10%' },
				{ value: 'themeName', text: 'Theme Name', width: '35%' },
				{ value: 'cassieDefault', text: 'Theme Type', width: '15%' },
				{ value: 'isActive', text: 'In Use', width: '10%' },
				{ value: 'createdDate', text: 'Created Date', width: '15%' },
				{ value: 'action', text: 'Action', width: '15%' }
			]
		},
		filteredResources () {
			const allowedFileTypes = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'tiff', 'ttf']
			const filteredResources = this.formData.existingResources.filter(resource => allowedFileTypes.includes(resource.name.split('.').pop().toLowerCase()))
			return filteredResources
		},
		canCreateAndUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_THEMES)
		},
		canDelete () {
			return this.productAreaPermission(CAN_DELETE_THEMES)
		}
	},
	async created () {
		await this.loadThemes()
		await this.waitForLoad()
	},
	methods: {
		async loadThemes () {
			const { data: { themes, lookupData } } = await getThemes()
			this.lookupData = lookupData
			this.themes = themes.map(theme => {
				return {
					...theme,
					createdDate: format(new Date(theme.createdDate), DATE_FORMAT)
				}
			})
		},
		async loadThemeUrls () {
			const editThemeId = this.themeToEdit.themeId
			const themeUrls = await getThemeUrls(editThemeId)
			this.themeUrls = themeUrls.map(themeUrl => {
				return {
					name: themeUrl.name,
					url: themeUrl.url,
					type: themeUrl.type
				}
			})
		},
		closeThemeMappingModal () {
			this.themeMappingToView = null
		},
		onCreateClick () {
			this.showManageModal = true
		},
		async createTheme (formData) {
			this.showManageModal = false
			await createTheme(formData)
			await this.loadThemes()
		},
		async updateTheme (formData) {
			this.showManageModal = false
			await updateTheme(formData)
			this.themeToEdit = null
			await this.loadThemes()
		},
		onRemoveThemeClick (theme) {
			this.themeToBeRemoved = theme
		},
		async deleteTheme () {
			await deleteTheme(this.themeToBeRemoved.themeId)
			this.showSnackbar('Theme - ' + this.themeToBeRemoved.themeName + ' has been deleted')
			this.themeToBeRemoved = null
			await this.loadThemes()
		},
		async onEditClick (theme) {
			this.themeToEdit = theme
			await this.loadThemeUrls()
			this.themeToEdit = {
				...theme,
				script: this.themeUrls.find(tu => tu.type === 'js'),
				style: this.themeUrls.find(tu => tu.type === 'css'),
				resources: this.themeUrls
			}
			this.showManageModal = true
		},
		closeManageModal () {
			this.showManageModal = false
			this.themeToEdit = null
		}
	}
}
</script>
