import { configApi } from '../http-client.js'

export const getThemes = () => {
	return configApi.get('/api/themes')
}
export const getThemeUrls = themeId => {
	return configApi.get('/api/themes/getAssetUrls/' + themeId).then(({ data }) => data.fileUrls)
}
export const deleteTheme = themeId => configApi.delete(`/api/themes/?themeId=${themeId}`)

export const createTheme = theme => {
	return configApi.post('/api/themes', theme, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}
export const updateTheme = theme => {
	return configApi.put('/api/themes', theme, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}
export const deleteThemeAsset = fileToDelete => {
	return configApi.delete('/api/Themes/DeleteAsset',
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data: fileToDelete
		})
}
